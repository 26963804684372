.sidebar h1 {
  
}

.ol-control button {
  color: rgba(10,178,211,1);
}

.ol-control button:hover {
  color: rgba(10,174,207,0.5);
}

.bottombarButton i,
.sidebarButton i {
  color: rgba(10,178,211,1);
}

.bottombarButton:hover i,
.sidebarButton:hover i {
  color: rgba(10,174,207,0.5);
}

.topbar {
  background: rgba(204, 22, 26,1);
}

.searchbar i.ion-android-close {
  
}

.range-value-container div:after {
  border-color: rgba(10,178,211,1) transparent transparent transparent;
}

.range-slider input[type=range]::-moz-range-thumb {
  background-color: rgba(10,178,211,1);
}

.range-value-container div {
  background: rgba(10,178,211,1);
}

.skin-blue .sidebar-menu > li.active > a,
.skin-blue .sidebar-menu > li:hover > a {
  background: #F8F8F8;
  border-left-color: rgba(10,178,211,1);
}

.skin-blue .main-header .navbar .sidebar-toggle {
  color: rgba(10,178,211,1);
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: rgba(10,178,211,1);
}

.results {
  text-align: right;
  float: right;
  padding: 4px;
  background: #333;
  color: #FFF;
  border-radius: 2px;
  margin-top: -4px;
  display: inline-block;
}
