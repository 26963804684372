@font-face {
  font-family: 'Flaticon';
  src: url('../font/Flaticon.eot');
  src: url('../font/Flaticon.eot?#iefix') format('embedded-opentype'), url('../font/Flaticon.woff') format('woff'),
    url('../font/Flaticon.ttf') format('truetype'), url('../font/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('../font/Flaticon.svg#Flaticon') format('svg');
  }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-style: normal;
}

div[mode='popup'][data-qa='popup-mode-popup'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flaticon-drone:before {
  content: '\f100';
}

.flaticon-remote-control:before {
  content: '\f101';
}

body,
html {
  height: 100%;
  overflow: clip;
}

.cursorhelp {
  cursor: help;
}

.cursorhelp .deliverableDraggable,
.cursorhelp .deliverableHeader h1,
.cursorhelp .tutorialComponent.tuthighlighted .toolbar .openToolButton {
  cursor: help;
}

.Carousel {
  position: relative;
}

.Carousel-Selector-Selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.Carousel-slides {
  position: relative;
  height: 100px;
}

.Carousel-Slide {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.Carousel-Slide-Selected {
  opacity: 1;
}

.Carousel-Selector-Selected .Carousel-Selectors {
  z-index: 2;
}

.Carousel-Items {
  height: 100%;
  width: 100%;
}

.Carousel-Selectors {
  z-index: 1;
  text-align: right;
}

.Carousel-Selectors button {
  border: none;
  background: #fff;
  margin: 17px 5px 10px;
}

.Carousel-Selectors button:last-child {
  margin-right: -6px;
}

.Carousel-Selectors button i {
  font-size: 26px;
}

.Carousel-Selectors button:hover i {
  color: rgba(9, 99, 123, 1);
}

.Carousel-Selectors button i,
.Carousel-Selectors button:hover i {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.Carousel-header {
  display: inline-block;
  width: 100%;
  height: 80px;
  -webkit-box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.3);
}

.Carousel-Slide.Carousel-Slide-Selected {
  z-index: 5;
  display: block;
  -webkit-transition-delay: 1s;
  /* Safari */
  transition-delay: 1s;
  height: calc(100vh - 200px);
}

.Carousel-Slide-Selected {
  z-index: 4;
}

.loading {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
}

.loading .enfant {
  position: absolute;
  top: 50%;
  margin-top: -189px;
  left: 50%;
  margin-left: -150px;
}

.loading .logo {
  width: 300px;
  display: inline-block;
}

.toolbar.drawingToolbar .openToolButton {
  position: fixed;
  top: 10px;
  right: 90px;
}

.toolbar .undoActions {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 116px;
  left: 50%;
  top: 46px;
  height: 52px;
  margin-left: -58px;
}

.toolbar .undoActions .tools:last-child {
  margin-right: 0;
}

.toolbar.measuretoolbar.showed {
  width: auto;
}

.toolbar.measuretoolbar {
  z-index: 2;
  bottom: 10px;
  right: 145px;
  width: auto;
  position: fixed;
  height: 56px;
  -webkit-transition: left 1s ease;
  transition: left 1s ease;
  -o-transition: left 1s ease;
  display: flex;
  align-items: center;
}
.toolbar.measuretoolbar.show-right {
  right: 71px;
}

.toolbar.measuretoolbar .MeasureToolButton {
  margin-left: 90px;
}

.toolbar.measuretoolbar.disableElement {
  width: 55px;
  border-radius: 50%;
}

.toolbar.measuretoolbar.moved {
  left: 560px;
}

.toolbar.satellitetoolbar.moved {
  left: 480px;
}

.toolbar .tools {
  display: inline-block;
  width: 35px;
  height: 35px;
  transform: translateY(-20px);
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
  cursor: pointer;
  margin-right: 10px;
  -moz-transition: all 1s ease;
  color: #082c3f;
  opacity: 0;
  -moz-transition: all 1s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: hidden;
}

.logo img {
  width: 58px;
}

.toolbar .tools:nth-child(1) {
  -webkit-transition-delay: 0s;
  /* Safari */
  transition-delay: 0s;
}

.toolbar .tools:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  /* Safari */
  transition-delay: 0.1s;
}

.toolbar .tools:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  /* Safari */
  transition-delay: 0.2s;
}

.toolbar .tools:nth-child(4) {
  -webkit-transition-delay: 0.3s;
  /* Safari */
  transition-delay: 0.3s;
}

.toolbar.showed .tools {
  opacity: 1;
  visibility: visible;
  display: inline-block;
  transform: translateY(0px);
}

.toolbar.showed .tools.inactive {
  opacity: 0;
}

.toolbar .tools i {
  padding-top: 7px;
  display: block;
  font-size: 20px;
}

.toolbar .tools.selected,
.toolbar .tools:hover {
  color: #082c3f;
  background: #c6c30d;
}

.toolbar .openToolButton i {
  padding: 16px;
  display: block;
  font-size: 24px;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.toolbar .openToolButton.HelpButton {
  position: fixed;
  bottom: 60px;
  right: 67px;
  z-index: 15 !important;
  background: transparent;
  border-radius: none;
  box-shadow: none;
}

.tippy-tooltip[data-animatefill] {
  will-change: unset;
  background: #082c3f !important;
}

.toolbar .openToolButton.HelpButton img {
  width: 100px;
}

.toolbar .openToolButton.HelpButton i {
  position: absolute;
  top: 7px;
  left: 28px;
}

.toolbar .openToolButton:not(.MeasureToolButton):not(.SatelliteToolButton).closeTool i {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.SatelliteToolButton i.fa {
  font-size: 22px;
  transition: none;
}

.backButton div,
.backButton div i,
.fieldForm button.submitButton i,
.formFirstStepSubmit i,
.searchbar button i,
.toolbar .openToolButton i {
  color: rgba(198, 195, 13);
}

.backButton div,
.backButton div i,
.fieldForm button.submitButton i,
.formFirstStepSubmit i,
.searchbar button i,
.toolbar .openToolButton .ion-close-round {
  color: #082c3f;
}

.fieldForm button.submitButton[disabled],
.formFirstStepSubmit[disabled] {
  background: rgba(198, 195, 13, 0.4);
  color: rgba(8, 44, 63, 0.4);
}

.toolbar.drawingToolbar .drawingTools,
.toolbar.drawingToolbar .openToolButton,
.toolbar.drawingToolbar .undoActions {
  z-index: 2;
}

.toolbar.drawingToolbar {
  position: fixed;
  display: flex;
  bottom: 40;
  right: 0;
  left: 0;
  z-index: 3;
  justify-content: center;
}

.openToolButton {
  position: fixed;
}

.backButton div,
.fieldForm button.submitButton,
.formFirstStepSubmit,
.toolbar .openToolButton {
  background: #082c3f;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.searchbar button {
  background: white;
}

.searchbar button.togglesearchbar div {
  background: #c6c30d;
}

.toolbar .openToolButton {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.searchbar button.delete,
.toolbar.showed .openToolButton {
  background: #c6c30d;
}

.searchbar button.delete.green {
  background: #c6c30d;
  margin-left: 5px;
  margin-right: 0;
}

.menu {
  position: fixed;
  z-index: 2;
}

.fieldViewContainer {
  height: 0;
}

.fieldViewContainer,
.searchListContainer {
  position: relative;
}

.advancedSearch {
  background: rgba(8, 44, 63, 0.8);
}

.advancedSearch,
.searchList {
  width: 364px;
  z-index: 1;
  position: fixed;
  left: 60px;
}

.advancedSearch {
  position: initial;
}

.advancedSearch {
  height: 0;
  text-align: center;
  top: 60px;
  overflow: hidden;
  -webkit-transition: height 1s ease-in-out;
  -moz-transition: height 1s ease-in-out;
  -o-transition: height 1s ease-in-out;
  transition: height 1s ease-in-out;
  border-bottom: 2px solid #fff;
  box-shadow: 0 -2px 2px rgba(8, 44, 64, 0.2), 0 0 15px 1px rgba(8, 44, 64, 0.15) inset;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@keyframes openadvanced {
  0% {
    padding: 0;
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    padding: 10px;
    overflow: visible;
  }
}

.advancedSearch.open {
  height: 45px;
  overflow: visible;
  background: #44626f;
  border-bottom: 2px solid #44626f;
  z-index: 1;
  text-align: center;
}

.advancedSearch.open.overlaySearch {
  z-index: 4;
}

.advancedSearch.open + .searchList {
  top: 40px;
  z-index: 2;
}

.searchList {
  max-height: 0;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  box-shadow: 0 2px 2px rgba(8, 44, 64, 0.2);
  overflow: auto;
  position: relative;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.searchListContainer.withFocus .searchList {
  max-height: calc(100% - 90px);
}

.ol-control,
.ol-control:hover {
  background: none;
  color: #c6c30d;
  border: 1px solid #082c3f;
  padding: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.ol-control button {
  margin: 0;
  color: #c6c30d !important;
}

.ol-attribution {
  bottom: 0;
  right: 0;
  border-top-left-radius: 5px;
  color: #c6c30d;
  border-bottom-left-radius: 0;
  background: #082c3f;
  border-top-right-radius: 0;
  border-color: 1px solid #082c3f;
  border-bottom-right-radius: 0;
}

.ol-control button {
  border-color: 1px solid #082c3f;
  color: #c6c30d;
  background: #082c3f;
}

.ol-zoom {
  display: none;
  border-radius: 7px;
  top: auto;
  left: auto;
  bottom: 30px;
  right: 65px;
}

.sidebarContainer {
  position: fixed;
  z-index: 5000;
  width: 400px;
  left: 0;
}

.sidebar {
  width: 370px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
}

.bottombarButton,
.sidebarButton {
  position: absolute;
  cursor: pointer;
  z-index: 4999;
  background: rgba(255, 255, 255, 1);
}

.sidebarButton i {
  font-size: 18px;
}

.sidebarButton i::before {
  -ms-transform: rotate(90deg) rotateX(0deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg) rotateX(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(90deg) rotateX(0deg);
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.bottombarButton i {
  font-size: 22px;
}

.bottombarButton i::before {
  -ms-transform: rotateY(360deg);
  /* IE 9 */
  -webkit-transform: rotateY(360deg);
  /* Chrome, Safari, Opera */
  transform: rotateY(360deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.bottomContainer.open .bottombarButton i::before {
  -ms-transform: rotateY(0deg);
  /* IE 9 */
  -webkit-transform: rotateY(0deg);
  /* Chrome, Safari, Opera */
  transform: rotateY(0deg);
}

.bottomContainer {
  position: fixed;
  z-index: 5000;
  width: 350px;
  bottom: -200px;
  height: 230px;
  left: 50%;
  margin-left: -175px;
}

.bottombar {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 350px;
  padding: 20px;
  height: 200px;
  position: absolute;
  bottom: 0;
  background: #fff;
  z-index: 5000;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.bottombarButton {
  left: 50%;
  width: 80px;
  z-index: 4999;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
  height: 30px;
  bottom: 200px;
  padding: 6px 32px;
  margin-left: -40px;
}

.bottomContainer.open {
  -ms-transform: translate(0, -200px);
  /* IE 9 */
  -webkit-transform: translate(0, -200px);
  /* Safari */
  transform: translate(0, -200px);
}

.searchbar.closed {
  width: 56px;
}

.searchbar.closed input {
  margin-left: -86px;
  width: 10px;
  opacity: 0;
}

.searchbar.closed button.openFilters {
  border-right: 1px solid transparent;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}

.searchbar button.openFilters {
  width: 56px;
  margin: 0;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: #fff;
  padding: 10px 10px 10px 20px;
  border-right: 1px solid #ccc;
  height: 56px;
  font-weight: normal;
  -webkit-transition: all 1s ease;
  /* Safari */
  transition: all 1s ease;
}

.searchbar input:-ms-input-placeholder {
  color: #ababab;
  opacity: 1;
  letter-spacing: 2px;
}

button.backbutton {
  padding: 5px;
  border: none;
  position: absolute;
  top: -15px;
  left: -28px;
  z-index: 4;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

button.backbutton div {
  border: none;
  border-radius: 55%;
  vertical-align: top;
  width: 46px;
  padding-top: 7px;
  height: 46px;
  line-height: 32px;
  display: inline-block;
  font-size: 20px;
}

.searchbar button {
  margin-right: 6px;
  border: none;
  border-radius: 55%;
  vertical-align: top;
  width: 46px;
  margin-top: 5px;
  height: 46px;
  line-height: 32px;
  display: inline-block;
  font-size: 20px;
}

.searchbar button.togglesearchbar {
  background: #fff;
  margin: 0;
  padding: 0;
  height: 56px;
  width: 56px;
}

.searchbar button.togglesearchbar div {
  border: none;
  background: #c6c30d;
  border-radius: 55%;
  vertical-align: top;
  width: 46px;
  padding-top: 9px;
  height: 46px;
  line-height: 32px;
  display: inline-block;
  font-size: 20px;
}

.searchbar button i,
button.backButton div i {
  font-size: 30px;
  font-weight: bold;
  line-height: 32px;
  display: block;
}

.searchbar button i.ion-search {
  font-size: 28px;
}

.Carousel-Selectors button,
.Carousel-header {
  background: #f8f8f8;
}

h1 {
  font-family: 'Roboto';
  color: #000;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 18px;
}

:focus {
  outline: 0;
}

.Carousel-Selectors button {
  background: #fdfdfd;
}

.Carousel-header {
  background: transparent;
}

/** filters **/

ul.filterList {
  display: inline-block;
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-transition: max-height 1s ease;
  /* Safari */
  transition: max-height 1s ease;
  height: auto;
}

div.RangeContainer.closed {
  max-height: 0 !important;
}

div.RangeContainer {
  padding-left: 17px;
  padding-right: 33px;
  overflow: hidden;
  -webkit-transition: max-height 1s ease;
  /* Safari */
  transition: max-height 1s ease;
  height: auto;
}

.col-md-12.specialRange {
  padding-left: 0;
  padding-right: 0;
}

ul.filterList.closed {
  max-height: 0 !important;
}

.GroupItem {
  background: #082c3f;
  position: fixed;
  margin-top: -65px;
  width: 364px !important;
  z-index: 2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.incidencesTypeFilter ul.filterList li {
  font-size: 12px;
}

ul.filterList li,
ul.filterList li.selected {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.description .fieldItem .autocompletelist div:hover,
ul.filterList li.selected:hover {
  background: #082c3f;
  color: #ccc;
}

ul.filterList li:hover .fieldItem h5 {
  color: #c6c30d;
}

.incidencesFilter span.circle {
  margin-top: 5px;
  width: 15px;
  margin-right: 17px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

.filtermodule {
  overflow-y: auto;
  height: calc(100% - 160px);
}

.monthRange {
  margin-top: 20px;
}

.incidencesnumber {
  margin-top: 20px;
  font-family: 'Roboto Black';
  color: #000;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
}

body.modal-open,
html.modal-open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.modal {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
  visibility: hidden;
  z-index: 20;
}

.modal.visible {
  opacity: 1;
  transition-delay: 0s;
  visibility: visible;
}

.modal .modal-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 #555;
  color: #404040;
  font-size: 1rem;
  font-style: normal;
  margin: 50px auto;
  width: 90%;
  text-align: left;
}

.modal .modal-container .modal-header {
  background: #eee;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 1rem 1.5rem;
}

.modal .modal-container .modal-header:after {
  content: ' ';
  display: table;
  clear: both;
}

.modal .modal-container .modal-header h4.modal-heading {
  float: left;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
}

.modal .modal-container .modal-header span.modal-closer {
  color: #aaa;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.5rem;
  float: right;
}

.modal .modal-container .modal-header span.modal-closer:hover {
  color: #404040;
}

.modal .modal-container .modal-body {
  padding: 1em 1.5em;
  text-align: left;
}

.modal .modal-container .modal-body:after {
  content: ' ';
  display: table;
  clear: both;
}

.modal .modal-container .modal-footer {
  border-top: 1px dotted #f0f0f0;
  padding: 1em 1.5em;
  text-align: right;
}

.modal .modal-container .modal-footer:after {
  content: ' ';
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  .modal .modal-container {
    width: 500px;
  }
}

.modalContainerImg {
  width: 90%;
  height: 90%;
  text-align: center;
}

.modalContainerImg img {
  max-width: 100%;
  height: 100%;
}

.modalContainer .ion-close {
  font-size: 32px;
  position: absolute;
  color: #fff;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 500000;
}

.IncidencesNumberRange {
  height: auto;
}

.range-slider {
  height: 17px;
}

.incidenceImg {
  cursor: pointer;
}

.filterClick {
  cursor: pointer;
}

.range-slider input[type='range'].rhover {
  z-index: 120;
}

.OrderFilter {
  color: #9b9b9b;
  padding-right: 20px;
  padding-left: 15px;
  height: 30px;
  font-family: 'Montserrat';
  padding-top: 10px;
}

.OrderFilter div {
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e2e2e2;
}

.OrderFilter div.active,
.OrderFilter div.active i::before {
  font-weight: bold;
}

.OrderFilter div.right {
  text-align: right;
}

.OrderFilter div.left {
  text-align: left;
  padding-left: 0;
}

.OrderFilter div.col-md-6.right {
  text-align: right;
}

div.download {
  float: right;
  margin-right: 17px;
  margin-top: 4px;
  margin-bottom: -4px;
}

div.download a,
div.download button {
  display: inline-block;
}

.lineswitch,
.mapswitch,
.towerswitch {
  position: relative;
  cursor: pointer;
}

.lineswitch div {
  width: 25px;
  margin-top: 15px;
  background: #fff;
  height: 2px;
  position: relative;
}

.lineswitch div:after {
  position: absolute;
  top: -4px;
  left: -10px;
  width: 45px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 10px;
  content: '';
}

.lineswitch span,
.mapswitch span,
.towerswitch span {
  position: absolute;
  display: block;
  top: 6px;
  right: 0;
  padding: 5px;
  color: #fff;
  border-radius: 2px;
  font-size: 10px;
  font-family: 'Roboto';
  line-height: 4px;
  letter-spacing: 0.5px;
}

.lineswitch.open span,
.mapswitch.open span,
.towerswitch.open span {
  background: rgb(46, 204, 113);
}

.lineswitch.closed span,
.mapswitch.closed span,
.towerswitch.closed span {
  background: rgb(231, 76, 60);
}

.fieldItem {
  padding: 5px 0;
}

.dotz {
  color: #bbb;
  min-height: 17px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fieldItem div.desc-icon i {
  margin-right: 0;
}

.filedItem i {
  display: inline-block;
}

.fieldItem .references {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fieldItem h5 {
  font-size: 14px;
  margin: 5px 0px;
  line-height: 14px;
  font-size: 14px;
  color: #03293c;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.fieldItem .right {
  text-align: right;
}

.emptySnapshots {
  background: #fff;
  text-align: center;
  display: flex;
  color: #d31224;
}

.emptySnapshots div.content {
  margin: auto;
  display: block;
}

.emptySnapshots i {
  font-size: 150px;
}

.emptySnapshots h4 {
  font-size: 13px;
  margin-top: 20px;
}

/***** DESCRIPTION ****/

.description {
  color: #333;
  font-family: Roboto, 'Roboto Condensed';
  display: block;
  top: 80px;
  left: 80px;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 3;
  width: 330px;
  max-height: 40vh;
}

.opacitySliderWrapper {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  margin-top: 8px;
  margin-right: 8px;
}

.description h1 {
  margin: 0;
}

.description .fieldItem {
  padding: 10px 0;
  font-size: 17px;
  position: relative;
  background: rgba(8, 44, 63, 0.8);
  font-family: 'Roboto';
  color: #fff;
  min-height: auto;
}

.description .fieldItem div {
  font-size: 15px;
}

.description .fieldItem label {
  font-weight: 300;
}

.description .fieldItem div.autocomp,
.description .fieldItem div.autocomp div {
  padding: 0;
}

.description .fieldItem div.autocomp {
  width: 100%;
}

.description .fieldItem div.autocomp {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.description .fieldItem .fieldForm div.autocomp input {
  cursor: pointer;
}

.description .fieldItem .superficie div.desc-icon {
  margin-right: 8px;
}

.description .fieldItem div.desc-icon {
  padding: 0;
  float: left;
  display: block;
  margin-right: 8px;
}

.formSection div.desc-icon i,
.formSection div.desc-icon h2 {
  display: inline-block;
}

.formSection div.desc-icon h2 div {
  float: right;
}

.formSection div.desc-icon h2 {
  width: calc(100% - 20px);
  margin-top: 10px;
  padding-left: 10px;
  font-weight: 300;
  letter-spacing: 1px;
  font-family: Roboto, 'Roboto Condensed';
}

.formSection div.desc-icon h2,
.formSection div.desc-icon i {
  color: #c6c30d;
}

.fieldForm .formSection.col-md-12 {
  height: auto;
}

.description .fieldItem .superficie div.desc-icon {
  margin-top: 0;
}

.description .fieldItem {
  position: relative;
}

.advancedSearch h1,
.description .fieldItem .title h1 {
  font-size: 15px;
  color: #c6c30d;
  margin-left: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.advancedSearch h1 {
  color: #fff;
  margin: 10px 0 0 20px;
  text-align: left;
  text-transform: uppercase;
}

.advancedSearch h2 {
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
}

.advancedSearch .filter h2 {
  margin: 0 0 10px 0;
}

.advancedSearch .filter h2.selected {
  color: #c6c30d;
}

.advancedSearch h3 {
  color: #c6c30d;
  margin: 5px 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 13px;
  height: 13px;
}

.snapshotDescription {
  color: #9b9b9b;
  display: inline-block;
  width: 100%;
  margin-top: -33px;
  font-family: Roboto, 'Roboto Condensed';
  font-size: 17px;
}

.deliverableDraggable {
  z-index: 500000;
  display: grid;
  padding: 8px 0 0px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
}
ul.DeliverableDroppable > .deliverableDraggable:nth-last-child() {
  border-bottom: 0;
}

.deliverableDraggable.untemporal {
  -webkit-filter: blur(2px);
  cursor: default;
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  pointer-events: none;
}

.deliverableDraggable.undraggable .deliverableHeader h1 {
  cursor: default;
}

.deliverableDraggable.dragover {
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid #062433;
}

ul.DeliverableDroppable {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.deliverableHeader {
  width: 100%;
  color: #ccc;
  font-size: 12px;
  margin-bottom: 8px;
}

.deliverableHeader h1 {
  cursor: move;
  margin-top: 0;
  font-size: 12px;
  font-weight: normal;
  font-family: 'Roboto';
  line-height: 21px;
  margin-bottom: 0;
  color: #ccc;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

.deliverableHeader h1 i {
  display: inline-block;
  line-height: 11px;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 5px;
  font-size: 15px;
}

.deliverableHeader h1.disabled {
  cursor: default;
}

.layerBoxes {
  z-index: 1;
  border-radius: 5px;
  max-height: 575px;
  overflow-y: auto;
  overflow-x: hidden;
}

.layersBlock {
  overflow-y: auto;
}

.deliverablesWrapper,
.pdfwrapper {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.deliverablesWrapper {
  max-height: 300px;
  height: 100%;
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #082c3f;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c6c30d;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-clip: padding-box;
}

.listItemsortable {
  color: #333;
  z-index: 500000;
  font-weight: 400;
}

.deliverableInfo {
  display: block;
  overflow: hidden;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.deliverableInfo.closed {
  max-height: 0 !important;
}

.deliverableHeader .options {
  text-align: right;
  color: #fff;
}

.deliverableHeader .options i {
  cursor: pointer;
  font-size: 19px;
  display: inline-block;
}

.deliverableHeader .options i.ion-android-time,
.deliverableHeader .options i.ion-eye {
  margin-left: 2px;
  margin-right: 8px;
  font-size: 20px;
  color: #c6c30d;
}

.deliverableHeader .options i.ion-android-time.noLegend,
.deliverableHeader .options i.ion-eye.noLegend {
  margin-right: 24px;
}

.deliverableHeader .options i.fa-info-circle {
  color: #c6c30d;
}

.deliverableHeader .options i.ion-android-time.gray,
.deliverableHeader .options i.ion-eye.gray,
.deliverableHeader .options i.fa-info-circle.gray {
  color: #999;
}

.switchButtons {
  position: relative;
  display: inline-block;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.3);
  pointer-events: auto;
  margin-top: -40px;
}

.switchButtons i {
  font-size: 20px;
}

.switchButtons:after {
  content: '';
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.switchButtons button {
  vertical-align: bottom;
  border: none;
  padding: 10px;
  text-align: center;
  font-family: Roboto, 'Roboto Condensed';
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.switchButtons button:not(.active) {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3) inset, 0px 0 7px 0 rgba(0, 0, 0, 0.3) inset;
  background: #f8f8f8;
  color: #082c3f;
}

.switchButtons button:last-child {
  border-top-right-radius: 5px;
}

.pdfDeliverable.col-md-6 {
  padding: 20px;
  text-align: center;
  color: #d31224;
}

.pdfDeliverable i {
  font-size: 20px;
  color: #d31224;
}

.pdfDeliverable button div {
  font-family: 'Roboto';
  font-size: 12px;
  padding-top: 5px;
  color: #d31224;
  border-bottom: 1px solid #fff;
}

.pdfDeliverable a:link {
  text-decoration: none;
}

.pdfDeliverable a:hover div {
  border-bottom: 1px solid #d31224;
}

.pdfwrapper {
  overflow-y: auto;
}

.pdfwrapper ul {
  list-style-type: none;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}

.pdfwrapper li h3 {
  display: inline-block;
}

.pdfwrapper li button div.inputs {
  display: inline-table;
}

.pdfWrapper ul li {
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
}

.pdfwrapper li button div.pdfIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.pdfwrapper li div.inputs {
  display: inline-block;
  position: relative;
  margin-top: -40px;
  margin-bottom: -10px;
  margin-left: 50px;
  float: none;
}

.pdfwrapper ul li button:first-child {
  display: inline-block;
  position: relative;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  padding: 10px 8px;
  cursor: pointer;
}

.pdfwrapper ul li button,
.pdfwrapper ul li button h3,
.pdfwrapper ul li button i {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.pdfwrapper ul li button:hover h3,
.pdfwrapper ul li button:hover i {
  color: #c6c30d;
}

.pdfwrapper ul li button i {
  color: #ccc;
  font-size: 21px;
  margin-right: 5px;
}

.pdfwrapper ul li button h3 {
  margin-top: 0;
  font-size: 12px;
  font-family: 'Roboto';
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;
  color: #ccc;
  text-transform: capitalize;
}

.deliverableInfo .slider {
  margin-top: 10px;
  height: 12px;
  overflow: visible;
}

.bar {
  height: 5px;
}

.rangeColor {
  margin-top: 20px;
}

.opacitySliderWrapper.hidden {
  display: none;
}

.opacitySliderWrapper .bar.bar-1,
.rangeColor .bar.bar-0,
.rangeColor .bar.bar-2 {
  background: #ccc;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 1) inset;
}

.opacitySliderWrapper .bar.bar-0,
.rangeColor .bar.bar-1 {
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 1) inset, 0px 0 2px 3px rgba(0, 0, 0, 0.1);
}

.my-handle {
  background: #c6c30d;
  width: 15px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  height: 15px;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  margin-top: -6px;
}

.my-handle.opac {
  background: #082c3f;
}

.my-handle.opac i {
  display: block;
  top: -7px;
  position: absolute;
  color: #c6c30d;
}

.rangeColor .my-handle div {
  margin-top: -25px;
  font-family: Roboto, 'Roboto Condensed';
  color: #6b6b6b;
  display: inline-block;
  text-align: center;
}

.dialogContent > div:first-child {
  background: transparent !important;
}

.dialogContent > div:first-child > div:first-child {
  padding: 5px 0px !important;
  overflow: hidden !important;
}

.submitWrapper {
  position: fixed;
  width: 80px;
  bottom: 40px;
}

.autocompletelist {
  width: 278px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 12px rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 1);
  padding: 2px 0;
  position: fixed;
  z-index: 5000000;
  overflow: auto;
  max-height: 50%;
}

.description .fieldItem div .autocompletelist div {
  font-family: 'Roboto';
  font-size: 14px;
  padding: 5px 10px;
}

.surface {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 20px;
}

.surface .lab {
  padding-left: 25px;
}

.surface .right {
  text-align: right;
}

.description .fieldItem div.notify.col-md-12 {
  width: 100%;
  padding: 10px;
  margin-bottom: -15px;
  display: inline-block;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 2px solid #c6c30d;
  background: #fff;
  color: rgba(8, 44, 63, 0.8);
  font-family: 'Roboto';
  text-transform: none;
  white-space: inherit;
}

.description .fieldItem div.notify.col-md-12 .desc-icon {
  color: #082c3f;
  margin-left: 5px;
}

.SUCCESS_NOTIFICATION_CLASS,
.SUCCESS_NOTIFICATION_CLASS div,
.SUCCESS_NOTIFICATION_CLASS div div {
  background: rgb(46, 204, 113) !important;
  color: #fff !important;
}

.ALERT_NOTIFICATION_CLASS,
.ALERT_NOTIFICATION_CLASS div,
.ALERT_NOTIFICATION_CLASS div div {
  background: rgb(204, 198, 46) !important;
  color: #fff !important;
}

.ERROR_NOTIFICATION_CLASS,
.ERROR_NOTIFICATION_CLASS div,
.ERROR_NOTIFICATION_CLASS div div {
  background: red !important;
  color: #fff !important;
}

.deleteFieldBox {
  text-align: right;
  display: inline-block;
  width: 100%;
  padding-right: 20px;
}

.deleteConfirm {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
}

.deleteConfirm h2,
.deleteConfirm h1 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-top: 10px;
  color: #082c3f;
}

.deleteConfirm h1 {
  font-size: 20px;
}

.description .fieldItem div.surface {
  padding: 0;
}

.description .fieldItem div.deleteFieldWrapper {
  padding: 0;
}

.deleteFieldBox a {
  color: #d31224;
  font-family: 'Roboto';
  font-weight: normal;
  cursor: pointer;
  font-size: 14px;
}

.deleteConfirm button {
  width: 100px;
  border: none;
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: center;
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  letter-spacing: 1px;
}

.deleteConfirm button.delete,
.deleteConfirm button.noConfirm {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: red;
  border-radius: 50%;
  color: #082c3f;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  margin: 10px;
}

.deleteConfirm button.delete {
  background: #d31224;
  margin-right: 20px;
}

.deleteConfirm button.noConfirm {
  background: #c6c30d;
}

.flightSlider {
  width: 700px;
  height: 80px;
  cursor: move;
  background: transparent;
  position: fixed;
  bottom: 20px;
  left: 50%;
  text-align: right;
  margin-left: -350px;
  z-index: 2;
}

.flightSlider hr {
  width: calc(100% - 30px);
  display: block;
  position: absolute;
  top: 0;
  height: 4px;
  background: #082c3f;
  box-shadow: 0 2px 2px rgba(8, 44, 63, 0.3);
  border-radius: 5px;
  margin-left: 30px;
  border: 1px solid #082c3f;
}

.flightSlider .bar {
  width: 720px;
  display: block;
  position: relative;
  margin-top: 0;
  height: 4px;
  background: transparent;
}

.flightSlider .bar .flightbutton {
  display: inline-block;
  margin-top: 8px;
  text-align: center;
  width: 46px;
  margin: 8px 4px 0;
  cursor: pointer;
  height: 80px;
  vertical-align: top;
}

.flightSlider .bar .flightbutton.active {
  margin-top: 0;
}

.flightSlider .bar .flightbutton div.droneButton {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-top: 0;
  line-height: 40px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border: none;
  z-index: 3;
}

.flightSlider .bar .flightbutton.active div.droneButton {
  width: 48px;
  height: 48px;
  margin-top: 0;
  padding-top: 4px;
}

.flightSlider .bar .flightbutton div.snapshotDate {
  color: #082c3f;
  background: #fff;
}

.flightSlider .bar .flightbutton.disabled {
  cursor: default;
}

.flightSlider .bar .flightbutton.disabled .snapshotDate {
  opacity: 0.6;
}

.flightSlider .bar .flightbutton div.droneButton {
  color: #c6c30d;
  background: #082c3f;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.flightSlider .bar .flightbutton.active div.droneButton,
.flightSlider .bar .flightbutton:hover div.droneButton {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.flightSlider .bar .flightbutton.active div.droneButton {
  cursor: grab;
}

.flightSlider .bar .flightbutton.active div.snapshotDate,
.flightSlider .bar .flightbutton:hover div.snapshotDate {
  color: #c6c30d;
  background: #082c3f;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.ol-dragbox {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(100, 150, 0, 1);
}

.react-draggable {
  background: #082c3f;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -5px;
  width: 10px;
  height: 100%;
  cursor: move;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3), -2px 0 2px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.temporaldescription {
  position: fixed;
  z-index: 3;
  top: 0;
  background: #082c3f;
  color: #c6c30d;
  padding: 5px 0;
  width: 100px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
}

.temporaldescription-left {
  margin-left: -50px;
}

.temporaldescription-right {
  margin-right: -50px;
}

.react-draggable .temporalswipe {
  width: 40px;
  height: 40px;
  background: #082c3f;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  margin-left: -15px;
  z-index: 3;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3), -2px 0 2px rgba(0, 0, 0, 0.3);
}

.react-draggable .temporalswipe i.ion-navicon,
.react-draggable .temporalswipe i.fa-arrows-alt-h {
  font-size: 24px;
  margin-top: 8px;
  display: block;
  color: #c6c30d;
}

.flightSlider .bar .flightbutton div.droneButton.temporal i {
  font-size: 21px;
  line-height: 30px;
  position: relative;
  padding-top: 4px;
  margin-top: 0 !important;
}

.flightSlider .bar .flightbutton div.droneButton.temporal i::before {
  content: '\f3b3';
  font-family: 'Ionicons';
}

.flightSlider .bar .flightbutton div.droneButton.temporal::before,
.flightSlider .bar .flightbutton div.droneButton.temporalLeft::before {
  font-family: 'Ionicons';
  position: absolute;
  color: #082c3f;
  top: -40px;
  margin-left: -10px;
  font-size: 30px;
  font-weight: 100;
  text-shadow: 2px 0 #c6c30d, -2px 0 #c6c30d, 0px 2px #c6c30d, 0px -2px #c6c30d;
}

.flightSlider .bar .flightbutton div.droneButton.temporal::before {
  content: '\f10b';
}

.flightSlider .bar .flightbutton div.droneButton.temporalLeft::before {
  content: '\f108';
}

.flightSlider .bar .flightbutton.hovered div.snapshotDate {
  color: #c6c30d;
  background: #082c3f;
}

.flightSlider .bar .flightbutton div.snapshotDate {
  width: 46px;
  display: inline-block;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: relative;
  background: #fff;
  bottom: 0;
  margin-top: 12px;
  padding: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.flightSlider .bar .flightbutton div.snapshotDate .day,
.flightSlider .bar .flightbutton div.snapshotDate .months {
  display: inline-block;
  width: 100%;
}

.flightSlider .bar .flightbutton div.snapshotDate .months {
  color: #ccc;
  padding: 2px;
  background: #082c3f;
  font-size: 10px;
  height: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.flightSlider .bar .flightbutton div.snapshotDate .day {
  font-size: 14px;
  font-weight: bold;
  height: 20px;
}

.flightSlider .bar .flightbutton div.droneButton i {
  font-size: 15px;
  color: #ccc;
  display: block;
  margin-top: 8px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.flightSlider .bar .flightbutton div.droneButton i[class^='flaticon-'] {
  margin-top: -4px;
}

.flightSlider .bar .flightbutton.active div.droneButton:not(.temporal) i[class^='flaticon-'] {
  margin-top: -1px !important;
}

.flightSlider .bar .flightbutton div.droneButton.temporal i[class^='flaticon-'] {
  margin-top: -3px !important;
}

.flightSlider .bar .flightbutton.active div.droneButton i {
  font-size: 30px;
  color: #c6c30d;
  margin-top: 5px;
  font-weight: normal;
}

.flightSlider .bar .flightbutton div.snapshotDate:before {
  width: 0;
  left: 50%;
  margin-left: -5px;
  top: -5px;
  position: absolute;
  height: 0;
  content: '';
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #082c3f transparent;
}

.flightSlider .bar .flightbutton.active div.snapshotDate:before,
.flightSlider .bar .flightbutton:hover div.snapshotDate:before {
  border-color: transparent transparent #082c3f transparent;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  z-index: 3;
  background: rgba(8, 44, 63, 0.9);
  -webkit-transition: all 1s;
  transition: all 1s;
  opacity: 1;
}

.overlay.hiden {
  opacity: 0;
  visibility: hidden;
}

.deleteNotify {
  box-shadow: 0 2px 2px rgba(8, 44, 64, 0.3);
  width: 375px;
  padding: 10px;
  font-family: 'Roboto';
  color: #fff;
  border-radius: 5px;
  background: #c6c30d;
  position: absolute;
  bottom: -100px;
  left: 40px;
  z-index: 50;
  -webkit-animation-name: example;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 4s;
}

@keyframes example {
  0% {
    bottom: -100px;
  }
  25% {
    bottom: 40px;
  }
  90% {
    bottom: 40px;
  }
  95% {
    bottom: 50px;
  }
  100% {
    bottom: -100px;
  }
}

.fieldImagePin {
  font-size: 30px;
  cursor: pointer;
  color: #082c3f;
}

.fieldImagePin i {
  text-shadow: 2px 0 #c6c30d, -2px 0 #c6c30d, 0px 2px #c6c30d, 0px -2px #c6c30d;
}

.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  z-index: 0;
  white-space: nowrap;
}

.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.tooltip-static {
  opacity: 1;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  background-color: #082c3f;
  color: #c6c30d;
}

.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid #082c3f;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.tooltip-static:before {
  border-top-color: #082c3f;
}

.tooltip .measureDeleteButton {
  position: absolute;
  top: -12px;
  right: -10px;
  text-align: center;
  background: #d31224;
  border-radius: 50%;
  padding-top: 3px;
  color: #ffffff;
  width: 20px;
  height: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.tooltip .measureDeleteButton i {
  font-size: 14px;
}

.ol-overlay-container.ol-selectable.hideoverlay {
  display: none;
}

.loading {
  position: fixed;
  width: 300px;
  height: 400px;
  left: 50%;
  top: 50%;
  margin-top: 130px;
  margin-left: -158px;
}

.loading img {
  width: 300px;
  display: block;
  margin-bottom: 60px;
}

.cp-spinner {
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.cp-balls {
  animation: cp-balls-animate 1s linear infinite;
}

.cp-balls:before {
  border-radius: 50%;
  content: ' ';
  width: 24px;
  height: 24px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  animation: cp-balls-animate-before 1s ease-in-out infinite;
}

.cp-balls.white:before {
  background-color: white;
}

.cp-balls:after {
  border-radius: 50%;
  content: ' ';
  width: 24px;
  height: 24px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #c6c30d;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: cp-balls-animate-after 1s ease-in-out infinite;
}

@keyframes cp-balls-animate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cp-balls-animate-before {
  0% {
    transform: translate(-5px, -5px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-5px, -5px);
  }
}

@keyframes cp-balls-animate-after {
  0% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(5px, 5px);
  }
}

.dialogImg {
  -webkit-transition: all 1s;
  transition: all 1s;
}

.blur {
  filter: blur(5px);
}

.paper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.paper img {
  box-shadow: rgba(0, 0, 0, 0.25) 0 14px 45px, rgba(0, 0, 0, 0.22) 0 10px 18px;
  border-radius: 5px;
}

.paper h1 {
  color: #fff;
  font-weight: 100;
}

.react-user-tour-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 5000;
}

.react-user-tour-container
  div:first-child:not(.react-user-tour-arrow):not(.react-user-tour-next-button):not(.react-user-tour-done-button):not(.numbers):not(.tutoBreadCrumb) {
  background: #fff !important;
  padding: 15px 15px 40px 15px !important;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.4) !important;
  height: auto !important;
  position: relative;
}

.react-user-tour-container h1 {
  color: #082c3f;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin: 0 0 5px;
}

.react-user-tour-close {
  box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.2) !important;
  padding: 5px 7px !important;
  margin-top: -5px;
  margin-right: -1px;
  background: #c6c30d;
  color: #082c3f;
  border-radius: 50%;
}

.react-user-tour-back-button,
.react-user-tour-done-button,
.react-user-tour-next-button {
  width: auto !important;
  height: auto !important;
  background: #fff !important;
  padding: 5px !important;
  font-size: 20px;
  color: #082c3f;
}

.react-user-tour-done-button {
  color: #c6c30d !important;
}

.react-user-tour-back-button i,
.react-user-tour-done-button i,
.react-user-tour-next-button i {
  text-shadow: 0 1px 0 #082c3f, 0px -1px 0 #082c3f, -1px 0 0 #082c3f, 1px 0 0 #082c3f;
}

.react-user-tour-done-button i {
  font-size: 22px;
}

.tutoBreadCrumb {
  position: absolute;
  bottom: 10px;
  left: 14px;
  font-weight: bold;
  font-size: 14px;
}

.tutoBreadCrumb .numbers {
  display: inline-block;
  font-size: 25px;
  margin-right: 5px;
}

.tuthighlighted {
  position: relative;
  z-index: 4 !important;
}

.disableElement::after,
.removeOpacity::after {
  z-index: 5;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  display: block;
  border-radius: inherit;
  top: 0;
}

.disableElement::after {
  background: rgba(0, 0, 0, 0.7);
}

.removeOpacity,
.openToolButton.removeOpacity {
  z-index: 5;
}

.removeOpacity::after {
  background: rgba(0, 0, 0, 0);
}

.paper div.date {
  color: grey;
}

/*=============================================>>>>>
= Ruler Icon =
===============================================>>>>>*/

@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?98637674');
  src: url('../font/fontello.eot?98637674#iefix') format('embedded-opentype'), url('../font/fontello.woff2?98637674') format('woff2'),
    url('../font/fontello.woff?98637674') format('woff'), url('../font/fontello.ttf?98637674') format('truetype'),
    url('../font/fontello.svg?98637674#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ruler:before {
  content: '\f044';
}

/*= End of Ruler Icon =*/

/*=============================================<<<<<*/

/**** LOGIN ***/

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  background: #082c3f;
  font-family: Roboto;
}

.mui-textfield,
.mui-textfield > input {
  font-family: 'Roboto';
  font-weight: 300;
  line-height: 14px !important;
  letter-spacing: 1px;
}

.mui-textfield--float-label > label {
  color: #eee;
  font-family: 'Roboto';
}

.mui-textfield > input:focus ~ label,
.mui-textfield > textarea:focus ~ label {
  color: #c6c30d;
  line-height: 14px !important;
}

.mui-btn {
  font-family: 'Roboto';
  font-weight: 700;
}

.mui-textfield > input:focus {
  border-bottom: 1px solid #c6c30d;
}

.error {
  display: block;
  width: 100%;
  color: #d31224;
  font-family: 'Roboto';
  font-weight: 300;
  letter-spacing: 1px;
  padding-left: 32px;
  height: 20px;
}

.logsubmit .submit {
  font-family: 'Roboto';
}

.mui-form div.col-md-1 {
  padding-top: 23px;
}

.mui-textfield > input,
.mui-textfield > textarea {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.mui-textfield--float-label > input:not(:focus).mui--is-not-empty ~ label,
.mui-textfield--float-label > input:not(:focus):not(:empty):not(.mui--is-empty):not(.mui--is-not-empty) ~ label,
.mui-textfield--float-label > input:not(:focus)[value]:not([value='']):not(.mui--is-empty):not(.mui--is-not-empty) ~ label,
.mui-textfield--float-label > textarea:not(:focus).mui--is-not-empty ~ label,
.mui-textfield--float-label > textarea:not(:focus):not(:empty):not(.mui--is-empty):not(.mui--is-not-empty) ~ label,
.mui-textfield--float-label > textarea:not(:focus)[value]:not([value='']):not(.mui--is-empty):not(.mui--is-not-empty) ~ label {
  color: #c6c30d;
  line-height: 14px !important;
}

.logo-container-layerlogo img {
  width: 200px;
}

.logo-container-title {
  color: #fff;
  text-align: center;
  letter-spacing: 60px;
  display: block;
  margin-left: 60px;
}

.logo-container-title span {
  display: block;
  font-size: 15px;
  margin-top: 20px;
  color: #c6c30d;
  margin-left: -40px;
  letter-spacing: 20px;
}

.logo-container-layerlogo {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.logo-container h1 {
  font-family: 'Roboto';
  text-transform: uppercase;
  margin-bottom: 50px;
}

.logo-container h1 span {
  font-size: 50px;
}

.logo-container {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.logo-container img {
  width: 100px;
  text-align: center;
  height: auto;
}

.checkbox label[for='remember_me'] {
  min-height: 30px;
}

.flag {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 21px;
  font-family: 'Roboto Condensed';
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.flag-menu-switch {
  top: 76px;
  right: 14px;
}

.flag-menu a,
.flag-menu a:hover {
  text-decoration: none;
}

.flag-menu-switch .flag {
  color: #082c3f;
  background: #c6c30d;
}

.flag-menu-item-container .flag {
  margin-top: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.flag-menu-item-container .flag a {
  color: #082c3f;
  text-decoration: none;
}
.flag-menu-item-container .flag:hover a {
  text-decoration: none;
}

.flag-menu-item-container .flag:hover {
  background: #c6c30d;
  text-decoration: none;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.flag-menu-item-container {
  width: 24px;
  height: 24px;
  z-index: 3;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
}

.flag-menu {
  position: absolute;
  top: 120px;
  right: 14px;
}

.flag-menu-item-container {
  animation: slidefromright, fadein;
  animation-duration: 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slidefromright {
  from {
    right: -15;
  }
  to {
    right: 0;
  }
}

.flag-container {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
}

.flag img {
  position: relative;
  width: auto;
  height: 100%;
  padding: 0;
  right: 12px;
  bottom: 12px;
}

/*= End of Language selection menu =*/

/*=============================================<<<<<*/

p.center {
  text-align: center;
}

ul.deliverableLegend {
  list-style-type: none;
  padding: 5px 30px;
  box-shadow: inset 0px 16px 25px -21px rgba(0, 0, 0, 0.7);
}

ul.deliverableLegend li.deliverableLegend--item {
  line-height: 20px;
}

ul.deliverableLegend li.deliverableLegend--item div {
  display: inline-block;
  width: 30px;
  height: 10px;
}

ul.deliverableLegend li.deliverableLegend--item label {
  margin-bottom: 0px;
  color: white;
  margin-left: 20px;
  font-weight: 300;
  font-size: 12px;
  font-family: 'Roboto';
  letter-spacing: 0.5px;
}

.legendWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 15px;
  z-index: 3;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.legend--title {
  background: #082c3f;
  color: #ccc;
  font-family: 'Roboto';
  font-size: 10px;
  padding: 0px 30px 0px 43px;
  letter-spacing: 1px;
  flex: 1 0 auto;
  position: relative;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.legend--title:after,
.legend--desc:after {
  width: 20px;
  height: 15px;
  position: absolute;
  right: -20px;
  z-index: 4000;
  content: '';
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.legend--title:after {
  background: linear-gradient(60deg, #082c3f 49%, rgba(0, 0, 0, 0) 51%);
}

.legend--desc:after {
  background: linear-gradient(60deg, #ffffff 49%, rgba(0, 0, 0, 0) 51%);
}

.legend--desc {
  background: #fff;
  color: #082c3f;
  font-family: 'Roboto';
  font-size: 10px;
  padding: 0px 30px;
  letter-spacing: 1px;
  flex: 1 0 auto;
  position: relative;
}

.far.fieldies {
  font-size: 10px;
  color: #fffc2a;
  margin: 0px 1px;
}

.ViewerApp {
  margin-top: -60px;
}
